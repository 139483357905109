import componentInitializer from "@/frontend/utils/componentInitializer"
import Vue, { defineAsyncComponent } from "vue"
import store from "@/store"
import { apolloProvider } from "@/lib/Apollo"

const renderAddCartIcon = () => {
  const targets = document.querySelectorAll("[data-listing-add-cart-button]")

  targets.forEach((el) => {
    const props = JSON.parse(el.dataset.listingAddCartButton)
    const ListingAddCartBtn = defineAsyncComponent(() => import("@/components/Listing/AddCartBtn.vue"))

    new Vue({
      el,
      apolloProvider,
      store,
      render: (h) =>
        h(ListingAddCartBtn, {
          props,
        }),
    })
  })
}

componentInitializer(renderAddCartIcon)
// Watch for add cart icon changes and sets the icon
componentInitializer(() => {
  window.addCartObserver?.disconnect()

  const targets = document.querySelectorAll('[data-watch-add-cart-icon=""]')

  if (targets.length === 0) return

  const config = { childList: true, subtree: true }
  const observer = new MutationObserver(() => {
    renderAddCartIcon()
  })

  targets.forEach((t) => {
    observer.observe(t, config)
  })
  window.addCartIconObserver = observer
})
